import { ThemeProvider } from "@emotion/react";
import type { FC } from "react";
import { Container, CssBaseline, Grid } from "@mui/material";
import styled from "@emotion/styled";

import theme, { secondaryColor } from "theme";

import CategoryView from "./Category";
import useMenu from "./useMenu";
import type { Category } from "./types";
import LoadingState from "./LoadingState";

const Header = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: relative;
  ${theme.breakpoints.down("sm")} {
    padding: 8px;
    padding-bottom: 32px;
  }
`

const Title = styled.div`
  font-weight: 100;
  color: red;
  color: ${secondaryColor};
  font-size: 64px;
  ${theme.breakpoints.down("sm")} {
    font-size: 48px;
  }
`

const Logo = styled.img`
  width: 150px;
  ${theme.breakpoints.down("sm")} {
    width: 64px;
  }
`

const Menu: FC = () => {
  const { loading, categories, products } = useMenu()

  const getCategoryProducts = (category: Category) => {
    const subcategoryIds = category.children?.map((subcategory) => subcategory.id) || []
    const allCategoryIds = [category.id, ...subcategoryIds]

    return products.filter((product) => allCategoryIds.includes(product.categoryId))
  }

  const handleInstagramClick = () => {
    // @ts-expect-error gtag is defined in index html file
    window.gtag("event", "visitInstagram", {
      event_category: "Social Media",
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadingState loaded={!loading} />
      <Container maxWidth="lg" style={{
        opacity: loading ? 0 : 1,
        transition: 'opacity 0.2s ease-in-out'
      }}>
        <Header container>
          <Grid item xs={4}>
            <Title>МЕНЮ</Title>
          </Grid>
          <Grid item style={{ textAlign: 'center' }} xs={4}>
            <Logo alt="logo" src="/blue-square-logo.png" />
          </Grid>
          <Grid item style={{ textAlign: 'right' }} xs={4}>
            <a
              href="https://instagram.com/atmo.cafe"
              onClick={handleInstagramClick}
              style={{ cursor: 'pointer' }}
            >
              <img alt="logo" src="/icons/Instagram_Glyph_Gradient.png" width="40px" />
            </a>
          </Grid>
        </Header>
        <Grid container spacing={4}>
          {categories.map((category) =>
            <Grid key={category.id} item lg={6} md={6} sm={12} xs={12}>
              <CategoryView
                category={category}
                products={getCategoryProducts(category)}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </ThemeProvider >
  )
}

export default Menu;