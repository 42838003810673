import type { FC } from "react";

import type { FormattedProduct } from "../types";
import { Wrapper, Subtitle, Price, Notice } from "./styles";

type Props = {
  product: FormattedProduct;
}

const ProductItem: FC<Props> = ({ product }) => product.isMultiProduct ? (
  ([product.subtitle].flatMap(item => item)).map((subtitle, index: number) => (
    <Wrapper key={index}>
      <div>
        <span>
          {product.name} {subtitle}
        </span>
      </div >
      {
        product.prices && <Price>{product.prices[index]}</Price>
      }
    </Wrapper>
  ))
) : (
  <Wrapper>
    <div>
      <span>
        {product.name}
        <Notice>
          {' '}
          {product.notice}
        </Notice>
      </span>
      <Subtitle>
        {product.subtitle}
      </Subtitle>
    </div >
    <Price>
      {product.prices?.join(' / ')}
      {product.includesMilk && (<img alt="vegan" src="/icons/leaf.svg" width={16} />)}
    </Price>
  </Wrapper>
)

export default ProductItem;