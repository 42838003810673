import { createTheme } from "@mui/material";

export const primaryColor = "#193058";
export const secondaryColor = "#8c6439";

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
  },
  typography: {
    fontFamily: ["Champagne & Limousines", "sans-serif"].join(","),
  },
});

export default theme;
