import { useEffect, useState } from "react";

import type { FormattedProduct, Category } from "./types";

function objectToQueryString(obj: Record<string, string>) {
  const keyValuePairs = Object.keys(obj).map((key) => {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(obj[key]);

    return `${encodedKey}=${encodedValue}`;
  });

  return keyValuePairs.join("&");
}

const lambdaProxyUrl =
  "https://kwpjrqgloh.execute-api.eu-central-1.amazonaws.com/default/atmo-poster-proxy";

const getProducts = async (): Promise<readonly FormattedProduct[]> => {
  const queryString = objectToQueryString({ action: "getMenu" });

  return new Promise((resolve) => {
    fetch(`${lambdaProxyUrl}?${queryString}`)
      .then((res) => res.json())
      .then((data) => resolve(data));
  });
};

export const getBaristaInfo = async (): Promise<{
  safeBalanceCents: number;
}> => {
  const queryString = objectToQueryString({ action: "getBaristaInfo" });

  return new Promise((resolve) => {
    fetch(`${lambdaProxyUrl}?${queryString}`)
      .then((res) => res.json())
      .then((data) => resolve(data));
  });
};

const categories = [
  {
    name: "Кава",
    id: "-1",
    children: [
      { name: "", id: "19", icon: "/icons/coffee.png" },
      { name: "Холодна кава", id: "20", icon: "/icons/bambl.png" },
      { name: "Альтернатива", id: "23" },
    ],
  },
  {
    name: "Не кава",
    id: "-2",
    children: [
      { name: "Освіжаюче", id: "3" },
      { name: "Зігріваюче", id: "8", icon: "/icons/frape.png" },
      { name: "Матча", id: "11" },
      { name: "Добавки", id: "15" },
    ],
  },
  {
    name: "Напої",
    id: "6",
    children: [
      { name: "Cоки", id: "24" },
      { name: "Вода", id: "26" },
      { name: "Інше", id: "25" },
    ],
  },
  {
    name: "Смачненьке",
    id: "-3",
    children: [
      { name: "Смаколики", id: "7", icon: "/icons/cake.png" },
      { name: "Солодощі", id: "14", icon: "/icons/sweets.png" },
    ],
  },
];

type Menu = {
  categories: readonly Category[];
  products: readonly FormattedProduct[];
  loading: boolean;
};

const useMenu = (): Menu => {
  const [products, setProducts] = useState<readonly FormattedProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await getProducts();

      setProducts(response);
      setLoading(false);
    };

    fetchProducts();
  }, []);

  return {
    categories,
    products: products.filter((product) => !product.name.includes("укрик")),
    loading,
  };
};

export default useMenu;
