import styled from "@emotion/styled";

import theme, { primaryColor } from "theme";

export const Wrapper = styled("div")(
  () => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  letter-spacing: 1px;
  font-weight: 100;
  font-size: 16px;
  color: ${primaryColor};
  padding-left: 32px;
  padding-right: 16px;
  font-weight: 600;
  ${theme.breakpoints.down("sm")} {
    padding-left: 12px;
    padding-right: 8px;
  }
`
);

export const Subtitle = styled("div")(
  () => `
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  color: ${primaryColor};
`
);

export const Price = styled("div")(
  () => `
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  display: flex;
`
);

export const Notice = styled("span")(
  () => `
  opacity: 0.5;
  font-weight: 100;
`
);
