import { useEffect, type FC, useState } from "react";

import { getBaristaInfo } from "menu/useMenu";

const BaristaInfo: FC = () => {
  const [safeBalance, setSafeBalance] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBaristaInfo = async () => {
      const response = await getBaristaInfo();

      setSafeBalance((response.safeBalanceCents) / 100);
      setLoading(false);
    };

    fetchBaristaInfo();
  }, []);

  if (loading) {
    return <h2>Завантаження...</h2>
  }

  return (
    <h2>
      <b>Баланс сейфу: </b><span>{safeBalance} грн.</span>
    </h2>
  )
}

export default BaristaInfo;