import type { FC } from "react";
import styled from "@emotion/styled";

import theme, { secondaryColor } from "theme";

import type { Category, FormattedProduct } from "./types";
import ProductItem from "./ProductItem";

type Props = {
  category: Category;
  products: readonly FormattedProduct[];
};

const Wrapper = styled.div`
  color: #121212;
  position: relative;
`

const CategoryName = styled.h1`
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 4px;
  text-transform: uppercase;
  border: 1px solid ${secondaryColor};
  padding: 8px 16px;
  color: ${secondaryColor};
  ${theme.breakpoints.down("sm")} {
    padding: 4px 8px;
    margin-bottom: 10px;
  }
`

const Subcategory = styled.div`
  position: relative;
`

const SubcategoryName = styled.div`
  font-size: 20px;
  font-weight: 600;
  &:not(:first-of-type) {
    margin-top: 40px;
  }
  margin-bottom: 12px;
  letter-spacing: 2px;
  text-align: left;
  color: ${secondaryColor};
  padding-left: 16px;
  ${theme.breakpoints.down("sm")} {
    padding-left: 12px;
  }
  `

const Icon = styled.img`
  position: absolute;
  right: 0;
  top: -160px;
  opacity: 0.5;
  width: 50%;
`

const CategoryView: FC<Props> = ({ category, products }) => (
  <Wrapper>
    <CategoryName>{category.name}</CategoryName>
    {category.icon &&
      <Icon alt="" src={category.icon} />
    }
    {products.filter((product) => product.categoryId === category.id).map((product) =>
      <ProductItem key={product.id} product={product} />
    )}
    {category.children?.map((subcategory) => (
      <Subcategory key={subcategory.id}>
        <SubcategoryName >{subcategory.name}</ SubcategoryName>
        {subcategory.icon &&
          <Icon alt="" src={subcategory.icon} />
        }
        {products.filter((product) => product.categoryId === subcategory.id).map((product) =>
          <ProductItem key={product.id} product={product} />
        )}
      </Subcategory>
    ))}
  </Wrapper>
);

export default CategoryView;
