import type { FC } from "react";

type Props = {
  loaded: boolean;
}

const LoadingState: FC<Props> = ({ loaded }) => (
  <img alt="loading" src="/hands-from-bottom.png" style={{
    position: 'absolute',
    bottom: '0',
    right: '0',
    opacity: loaded ? 0 : 1,
    transition: 'opacity 0.2s ease-in-out',
    maxWidth: '100vw',
  }} />
)

export default LoadingState;